<template>
    <div class='mine'>
        <div class="userInfo">
            <div class="headImg">
                <img class="img" :src="headImg" alt="">
            </div>
            <div class="userName">{{ userInfo.userName }}</div>
            <div class="userId">ID:{{ userInfo.autoid }}</div>
            <div class="point">
                <div class="personaldata " @click="routerPage('/updateUserInfo')">个人资料></div>
                <div class="memberdata" v-for="(item, index) in gridData" :key="index" @click="routerPageVip(item)">{{
                    item.text
                }}></div>
            </div>
        </div>
        <div class="content">
            <!-- <van-grid>
                <van-grid-item :icon="item.icon" :text="item.text" v-for="(item, index) in gridData" :key="index"
                    @click="routerPage(item.path)" />
            </van-grid> -->
            <!-- vip会员 -->
            <div class="vip">
                <img class="bgvip" src="../../assets/vip/vip1.png" alt="">
                <img class="crownvip" src="../../assets/vip/vip.png" alt="">
                <div class="introduce">
                    <div class="aboutmember">
                        <div class="member">
                            <div>初级会员</div>
                            <div>截止有效期</div>
                        </div>
                        <div class="experience">
                            <div>七天体验卡</div>
                            <div class="residue">还剩下六天</div>
                        </div>
                    </div>
                    <van-rate v-model="value" color="#FF9580" />
                    <button class="buy">购买会员</button>
                    <div class="ID">ID.CZ0000088</div>
                </div>
            </div>

            <!-- 邀请有礼 -->
            <div class="invite ">
                <img class="inviteimg" src="../../assets/vip/invite.png" alt="">
                <div class="politeness">邀请有礼</div>
                <div class="all">
                    <img class="logo" :src="logoImg" alt="">
                    <div class="present">
                        <div>共创一个快乐的世界</div>
                        <div>每次邀请一位新用户双方获得7天免费会员</div>
                        <div style="font-size:20px">邀请越多赚取越多</div>
                        <button class="invitebtn">立即邀请</button>
                    </div>
                    <img class="presentImg" src="../../assets/vip/present.png" alt="">

                </div>
            </div>

            <!-- 订单管理 -->
            <div class="backdrop">
                <div class="pillar">订单管理 </div>
                <div class="line"></div>
                <div class="management">
                    <div>
                        <van-icon name="orders-o" />
                        <div>全部订单</div>
                    </div>
                    <div>
                        <van-icon name="pending-payment" />
                        <div>待付款</div>
                    </div>
                    <div>
                        <van-icon name="send-gift-o" />
                        <div>待发货</div>
                    </div>
                    <div>
                        <van-icon name="sign" />
                        <div>待收货</div>
                    </div>
                </div>
            </div>

            <!-- 我的报告 -->
            <div class="backdrop  myreport">
                <div class="pillar">我的报告 </div>
                <div class="line"></div>
                <div class="report">
                    <div class="health" v-for="(item, index) in reportData" :key="index" @click="routerPage(item.path)">
                        <img :src="item.url" alt="">
                        <div>{{ item.text }}</div>
                    </div>
                </div>
            </div>

            <!-- 我的健康轨迹 -->
            <div class="backdrop myheal">
                <div class="pillar">我的健康轨迹 </div>
                <div class="line"></div>
                <div class="heal">
                    <div class="myhealing">我的疗愈</div>
                    <!-- <button class="gobtn">GO ></button> -->
                    <img class="goImg" src="../../assets/go.png" alt="">
                    <div class="quantity">
                        <div class="theme">
                            <div>累计疗愈主题</div>
                            <div class="add">80<span>个</span></div>

                        </div>
                        <div>
                            <div>累计疗愈时长</div>
                            <div class="add">156<span>分钟</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 我的服务 -->
            <div class="backdrop myservice">
                <div class="pillar">我的服务 </div>
                <div class="line"></div>
                <div class="service">
                    <div @click="connectingDevices">
                        <img src="../../assets/service/service1.png" alt="">
                        <div>连接设备</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service2.png" alt="">
                        <div>家庭成员</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service3.png" alt="">
                        <div>我的卡券</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service4.png" alt="">
                        <div>地址管理</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service5.png" alt="">
                        <div>系统设置</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service6.png" alt="">
                        <div>我的积分</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service7.png" alt="">
                        <div>关于我们</div>
                    </div>
                    <div>
                        <img src="../../assets/service/service8.png" alt="">
                        <div>帮助与客服</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetUserInfo } from '../../api/index'
import { mapGetters } from 'vuex'
import wx from '@/util/jweixin1.3.2'
export default {
    components: {
    },
    data() {
        return {
            userInfo: {},
            headImg: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/head.png',
            logoImg: 'https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/core/logo图标@2x.png',
            gridData: [
                {
                    icon: 'photo-o',
                    text: '会员服务',
                    type: 'mini',
                    path: '/pages/bindMember/index'
                }
            ],
            value: 3,
            reportData: [
                {
                    url: `${this.$imgDomain}/www/HealthingCore/report(1).png`,
                    text: '情绪与健康分析',
                    path: '/moodAndHealth'
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/report(2).png`,
                    text: '全身器官功能&储备能力'
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/report(3).png`,
                    text: '功能医学检测分析'
                },
                {
                    url: `${this.$imgDomain}/www/HealthingCore/report(4).png`,
                    text: '我的食疗报告',
                    path: '/dietReport'
                }
            ]
        }
    },
    props: {

    },
    created() {

    },
    mounted() {
        this.getUserInfo()
    },
    methods: {
        // 路由跳转
        routerPage(path) {
            this.$router.push(path)
        },
        routerPageVip(item) {
            console.log(item)
            if (item.type === 'mini') {
                // 跳转到小程序界面
                wx.miniProgram.navigateTo({
                    url: item.path
                })
            }
        },
        // 获取用户信息
        getUserInfo() {
            this.$toast.loading({
                message: '获取信息中'

            })
            GetUserInfo({ userid: this.userid }).then(res => {
                const { data } = res
                if (data) {
                    this.userInfo = data
                    console.log(data, "999")
                    window.localStorage.setItem(
                        "userInfo", JSON.stringify(data)
                    );
                      console.log(window.localStorage.getItem('userInfo'),"--------------")
                } else {
                    this.$toast.fail('信息获取失败')
                }
                this.$toast.clear()
            })
        },
        connectingDevices() {
            wx.miniProgram.navigateTo({
                url: '/pages/newBlueTooth/index',
            });

        }
    },
    watch: {

    },
    computed: {
        ...mapGetters([
            'userid'
        ])
    }
}
</script>
<style lang='scss' scoped>
.mine {
    height: 100%;
    background: #9966ff;
    overflow-y: scroll;

    .userInfo {
        height: 30%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: scroll;

        .headImg {
            width: 60px;
            height: 60px;
            border-radius: 30px;
            margin-bottom: 15px;
        }

        .userName {
            color: #fff;
            font-size: 20px;
        }

        .userId {
            margin: 5px;
            font-size: 18px;
            color: #fff
        }

        .point {
            display: flex;
            justify-content: space-evenly;
            font-size: 17px;
            color: #D0CEDB;
            // margin-left: -20px;;

            .personaldata {
                margin-left: -5px;
            }

            .memberdata {
                margin-left: 170px;
            }
        }
    }

    .content {
        background: #f2f2f2;
        border-radius: 20px 20px 0 0;

        .vip {
            width: 88%;
            padding: 3px;

            .bgvip {
                width: 100%;
                height: 150px;
                margin: 20px 23px;
            }

            .crownvip {
                width: 150px;
                margin: -160px 50px 30px 30px;
            }

            .introduce {
                margin: -175px 10px 30px 160px;
                color: #fff;

                .aboutmember {
                    display: flex;

                    .member {
                        text-align: right;
                        padding: 7px 6px;
                        border-right: 3px solid #FF9580;
                    }

                    .experience {
                        padding: 5px;

                        .residue {
                            font-size: 15px;
                        }
                    }
                }

                /deep/.van-rate {
                    margin-left: 30px;
                }

                .buy {
                    width: 90px;
                    height: 30px;
                    background: #FF9580;
                    border-radius: 10px;
                    border: 1px solid #FF9580;
                    color: #fff;
                    margin: 5px 50px;
                }

                .ID {
                    font-size: 20px;
                    color: #fff;
                    margin-left: 10px;
                }
            }
        }

        .invite {
            .inviteimg {
                width: 88%;
                margin: 15px 20px 100px 22px;
            }

            .politeness {
                height: 40px;
                margin: -320px 50px 150px 40px;
                font-size: 20px;
                color: #898989;
                position: relative;
            }

            .politeness::before {
                content: "";
                width: 10px;
                height: 20px;
                position: absolute;
                left: -15px;
                top: 35%;
                transform: translateY(-50%);
                border-radius: 5px;
            }

            .politeness::before {
                background: #FF9999;
            }

            .all {
                margin: -120px 50px -50px 40px;

                .logo {
                    width: 150px;
                    height: 30px;

                }

                .present {
                    color: #fff;
                    font-size: 12px;

                    div {
                        margin: 5px;
                    }
                }

                .presentImg {
                    width: 80px;
                    height: 130px;
                    margin: -150px 50px 100px 230px;
                }

                .invitebtn {
                    width: 120px;
                    height: 35px;
                    background: #FF9580;
                    border-radius: 10px;
                    border: 1px solid #FF9580;
                    color: #fff;
                    font-size: 15px;
                    margin: 5px 80px;
                }
            }
        }

        .backdrop {
            width: 90%;
            height: 150px;
            background-color: #ffffff;
            margin: 20px;
            border-radius: 10px;

            .pillar {
                position: relative;
                font-size: 20px;
                color: #898989;
                padding: 10px 20px;

            }

            .pillar::before {
                content: "";
                width: 10px;
                height: 20px;
                position: absolute;
                left: 5px;
                top: 50%;
                transform: translateY(-50%);
                border-radius: 5px;
            }

            .pillar::before {
                background: #FF9999;
            }

            .line {
                border-bottom: 1px dashed #969798;
            }

            .management {
                display: flex;
                justify-content: space-around;
                text-align: center;

                /deep/.van-icon {
                    font-size: 30px;
                    margin-top: 20px;
                }

                div {
                    margin-top: 5px;
                }
            }
        }

        .myreport {
            height: 380px;

            .report {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
            }

            .health {
                width: 140px;
                height: 140px;
                margin-top: 20px;
                text-align: center;
                color: #5f5f5f;

                img {
                    width: 100%;
                }

                div {
                    margin: -55px 5px;
                }
            }
        }

        .myheal {
            height: 200px;

            .heal {
                width: 90%;
                height: 120px;
                margin: 18px;
                background: #f9f9f9;
                border-radius: 10px;
                padding: 5px;
                color: #5f5f5f;

                .myhealing {
                    text-align: center;
                    font-size: 20px;
                    color: #898989;
                }

                .goImg {
                    margin: -20px 0px 0px 230px;
                    width: 40px;
                    height: 20px;
                    border-radius: 10px;
                }

                .quantity {
                    display: flex;
                    justify-content: space-evenly;
                    margin-top: 10px;
                    font-size: 18px;
                    text-align: center;

                    .add {
                        color: #FF9999;
                        font-size: 25px;
                    }

                    span {
                        color: #8f8f8f;
                        font-size: 15px;
                    }

                    .theme {
                        width: 135px;
                        border-right: 2px solid #d8d8d8;

                    }
                }
            }
        }

        .myservice {
            height: 280px;
            margin-bottom: 30px;

            .service {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                text-align: center;
            }

            img {
                width: 50px;
                height: 50px;
                margin: 10px;
            }
        }

    }
}
</style>
